import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../../services/auth.service';
import { HttpClientProvider } from '../../../services/http-client';
import { LoadingService } from '../../../services/loading.service';
import { NotificationService } from '../../../services/notification.service';
import { SettingsProvider } from '../../../services/settings';
import { UserModel } from '../../../services/user-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getEnvironment } from '../../../environment/environment';

@Component({
  selector: 'app-bypass-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bypass-login.page.html',
  styleUrl: './bypass-login.page.scss'
})
export class BypassLoginPage implements OnInit {
  url?: string = '';
  token?: string = '';

  env = getEnvironment();

  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClient,
    private load: LoadingService,
    private toast: NotificationService,
    private authService: AuthService,
    private auth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.authService.clearDataWithoutRedirect();
      this.url = params['url'];
      this.token = params['token'];

      // Ahora puedes usar this.url y this.bearer como desees en tu componente
      console.log('URL:', this.url);
      console.log('Bearer:', this.token);
      if (this.url && this.token) {
        this.loginProcess(this.url)
      }


    });
  }

  loginProcess(url: string,) {
    this.load.loadingShow();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
    this.http.get(`${this.url}`, { headers: httpHeaders }).subscribe(async (result: any) => {
      console.log(result);
      if (result['status'] && result['data']) {
        let data = result['data'];
        this.authService.successfullOAuthAuthorization(true, data['token'], data['expirationDate'], data['user']);
        this.load.dismiss();
        await this.authService.getUserByToken().toPromise();
        if(data?.redirect) {
          this.router.navigateByUrl(data?.redirect);
        } else {
          this.router.navigateByUrl(`/${this.env.authRedirect}`);
        }
        this.toast.success('Sesión Iniciada', 'Sesión iniciada correctamente', 4000);
      } else {
        this.load.dismiss();
        this.toast.error('Error al iniciar Sesión', (result['message'] ?? 'Los datos introducidos no son correctos.'), 4000);
      }
    }, (error: any) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al iniciar Sesión', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

}
