import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { LoadingService } from '../../../services/loading.service';
import { NotificationService } from '../../../services/notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getEnvironment } from '../../../environment/environment';
import { SettingsProvider } from '../../../services/settings';

@Component({
  selector: 'app-external-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './external-login.page.html',
})
export class ExternalLoginPage implements OnInit {
  
  token?: string;
  redirect?: string;
  external?: string;

  env = getEnvironment();

  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClient,
    private load: LoadingService,
    private toast: NotificationService,
    private authService: AuthService,
  ) {
    console.log('here');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.authService.clearDataWithoutRedirect();
      this.token = params['token'] ?? undefined;
      this.redirect = params['redirect'] ?? undefined;
      this.external = params['external'] ?? undefined;

      console.log('Bearer:', this.token);
      if (this.token) {
        this.loginProcess()
      }
    });
  }

  loginProcess() {
    this.load.loadingShow();
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
    this.http.get(SettingsProvider.getUrl('external-sign-in'), { headers: httpHeaders }).subscribe(async (result: any) => {
      console.log(result);
      if (result['status'] && result['data']) {
        let data = result['data'];
        this.authService.successfullOAuthAuthorization(true, data['token'], data['expirationDate'], data['user']);
        this.load.dismiss();
        await this.authService.getUserByToken().toPromise();
        if(this.redirect) {
          if(this.external&&(this.external!=''&&this.external!='false')) {
            window.location.replace(this.redirect);
          } else {
            this.router.navigateByUrl(this.redirect);
          }
        } else {
          this.router.navigateByUrl(`/${this.env.authRedirect}`);
        }
        this.toast.success('Sesión Iniciada', 'Sesión iniciada correctamente', 4000);
      } else {
        this.load.dismiss();
        this.toast.error('Error al iniciar Sesión', (result['message'] ?? 'Los datos introducidos no son correctos.'), 4000);
      }
    }, (error: any) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al iniciar Sesión', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

}
