import { initializeApp } from '@angular/fire/app';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { LoadingService } from '../../../services/loading.service';
import { NotificationService } from '../../../services/notification.service';
import { getEnvironment } from '../../../environment/environment';
import { SettingsProvider } from '../../../services/settings';
import { HttpClientProvider } from '../../../services/http-client';

@Component({
  selector: 'app-my-session',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './my-session.page.html',
})
export class MySessionPage implements OnInit {
  
  token?: string;
  redirect?: string;
  external?: string;

  env = getEnvironment();

  constructor(private route: ActivatedRoute,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClientProvider,
    private load: LoadingService,
    private toast: NotificationService,
    private authService: AuthService,
  ) {
    console.log('here');
  }

  ngOnInit() {
      this.initializeApp();
  }

  initializeApp() {
    this.load.loadingShow();
    this.http.getRequest(SettingsProvider.getUrl('my-session')).subscribe(async (result: any) => {
      console.log(result);
      if (result['status'] && result['data']) {
        this.load.dismiss();
        if(result.data.url) {
          window.location.replace(result.data.url);
        } else {
          this.router.navigateByUrl(`/${this.env.authRedirect}`);
        }
      } else {
        this.load.dismiss();
        this.toast.error('Error al iniciar Sesión', (result['message'] ?? 'Los datos introducidos no son correctos.'), 4000);
      }
    }, (error: any) => {
      console.log(error);
      this.load.dismiss();
      this.toast.error('Error al iniciar Sesión', 'Hubo un error al conectar con el servidor, verifique su conexión a internet e intente nuevamente.', 4000);
    });
  }

}
